//import {useMutation} from '@apollo/react-hooks';
import React from 'react';
import NeverMissAnUpdate from '../../../never-miss-an-update';
import './styles.scss';

const Update = () => {
  return (
    <div className="update-section">
      <NeverMissAnUpdate />
    </div>
  );
};

export default Update;
