import React from 'react';
import JoinOurTeam from './components/join-our-team';
import OurStory from './components/our-story';
import Theme from './components/theme';
import Update from './components/update';
import WeAreHexact from './components/we-are-hexact';
import './styles.scss';

const Home = () => {
  return (
    <div className="home-page">
      <WeAreHexact />
      <Theme />
      <OurStory />
      {/* <JoinOurTeam /> */}
      <Update />
    </div>
  );
};
export default Home;
