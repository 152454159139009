import React, {ReactElement} from 'react';
import Cards from '../../../shared-components/card';
import {cardIcon1, cardIcon12, smileIcon} from '../../../../assets/svg-icons';

import './styles.scss';

const cardsInfo = [
  {
    bgColor: '#4262ff',
    text1: '5',
    text2: 'The Hexosystem consists of five core platforms.',
    icon: cardIcon1,
  },
  {
    bgColor: '#ffc500',
    text1: '167',
    text2: 'Our platforms empower users in 167 countries across 7 continents.',
    icon: cardIcon12,
  },
  {
    bgColor: '#00C869',
    text1: '130K+',
    text2: '130K users worldwide are using Hexoproducts.',
    icon: smileIcon,
  },
];
const WeAreHexact = () => (
  <div className="we-are-section-wrapper">
    <div className="we-are-fst-wrapper">
      <div className='d-flex justify-content-center'><span className="bg-blue">OUR STORY</span></div>
      <h2 className="we-are-hexact">We Are Hexact</h2>
      <div className="data-mining">No-code, point and click business intelligence solutions. </div>
      {/* <div className="cards-wrapper">
        {cardsInfo.map((card: {bgColor: string; text1: string; text2: string; icon: ReactElement}) => (
          <Cards key={card.bgColor} bgColor={card.bgColor} text1={card.text1} text2={card.text2} icon={card.icon} />
        ))}
      </div> */}
      <div className="we-are-text mt-2 text-center">
        <div>
          Founded in 2019, Hexact is on a mission to empower businesses of all sizes with AI-driven sidekicks that work
          in the cloud.{' '}
        </div>
        {/* <div className="second-text text-center">
          Hexact helps over 100,000 users stay one step ahead of their market, catch website problems and automate
          time-consuming tasks at scale.
        </div> */}
      </div>
    </div>
  </div>
);
export default WeAreHexact;
