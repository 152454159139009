import React from 'react';
// import Subscribe from '../subscribe';
import Button from '../shared-components/button';
import './styles.scss';

const NeverMissAnUpdate = () => {
  const handleClick = () => {
    window.location.href = 'https://newsletter.hexact.io/subscribe';
  };

  return (
    <div className="blue_bg">
      <h5>Never miss an update!</h5>
      <p>
        Sign up for our newsletter to receive the latest company news, accomplishments, achievements, and future plans.
      </p>
      {/* <Subscribe /> */}
      <div className="d-flex justify-content-center mt-2">
        <Button btnText="Subscribe" width="180" onClick={handleClick} />
      </div>
    </div>
  );
};

export default NeverMissAnUpdate;
