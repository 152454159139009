import * as React from 'react';
//import Header from '../components/layout/header';
//import Footer from '../components/layout/footer';
import Layout from '../components/layout';
import {homeBase64} from '../assets/pagehero-base64/home-base64';
import Home from '../components/home';
import atob from 'atob';

// markup
const IndexPage = () => {
  return (
    <Layout
      pageheroColor="#4262FF"
      pageheroText="Delegate and scale your work, powered by the cloud."
      pageheroTitle={
        <>
          <div className="two-line">
            <div>Data Insights</div>
            <div>Growth</div>
          </div>
          <div className="three-line">
            <div>Data </div>
            <div>Insights</div>
            <div>Growth</div>
          </div>
        </>
      }
      pageheroImg={atob(homeBase64)}
      className="full-page large-padding"
    >
      <Home />
    </Layout>
  );
};

export default IndexPage;
